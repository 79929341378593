module CourseService {
    let app = angular.module("app");

    app.factory("CourseService", ["$resource", "GlobalApplicationData", "FileUploader", ($resource: ng.resource.IResourceService, GlobalApplicationData: any, FileUploader: any) => {
        let serv = {
            // Recupera l'url del Course Manager
            getCmUrl: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-cm-url', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera le informazione di un corso
            getCourseDetails: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-detail-by-id/:courseId', {
                courseId: "@courseId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),
            exportIvassCoursesRegistrations: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/export-ivass-courses-registrations', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera le informazioni di un'edizione
            getCourseDateById: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-date-by-id/:courseDateId', {
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera le informazioni di un'edizione
            ivassCoursesReport: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/ivass-courses-report', {
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il pdf dei corsi
            passportReport: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/passport-report', {

            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera le informazioni di un'iscrizione di un utente ad un'edizione
            getCourseUserDetail: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-user-detail', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Crea un corso
            createCourse: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/create-course', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Conferma l'iscrizione a un corso
            confirmSubscription: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/self-confirm-course-date-user', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge una risposta a una domanda
            addAnswerToQuestion: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-answer-to-question', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Rifiuta l'iscrizione a un corso
            refuseSubscription: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/self-cancel-course-date-user', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiorna un corso
            updateCourse: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-course', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Crea un'edizione
            createCourseDate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/create-courseDate', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiorna un'edizione
            updateCourseDate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-courseDate', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Bonifica i crediti
            recalcUsersAwardsByCourse: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/recalc-users-awards-by-course', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutti i corsi
            getAllCourses: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-all-courses', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutte le edizioni
            getCoursesAndDates: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-courses-and-dates-list/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Conteggia tutte le edizioni
            countCoursesAndDates: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-courses-and-dates-list', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutte gli utenti iscritti all'edizione del corso
            getCourseUsersList: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-users-by-id/:courseId/:courseDateId', {
                courseId: "@courseId",
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            getCourseDateDaysNoDefault: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-date-days', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            getCourseDateSelectedDays: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-date-selected-days', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: true
                    }
                }, {
                    cancellable: true
                }),

            // Verifica lo stato dell'utente relativamente a un corso
            checkUserInvitation: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/check-user-invitation/:courseId/:courseDateId/:userId', {
                courseId: "@courseId",
                courseDateId: "@courseDateId",
                userId: "@userId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge un lista di utenti ad un'edizione
            addUsersToCourseDate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-users-to-courseDate', {
                courseUserList: null
            }, {
                    insert: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Disiscrive una lista di utenti da un'edizione
            deleteUsersFromCourseDate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-users-from-courseDate', {
                courseUserList: null
            }, {
                    delete: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Cambio lo stato di uno o più utenti
            changeUsersStatus: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/change-user-status', {
            }, {
                    update: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera i corsi dell'utente
            getCourseListByUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-list-by-user/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera i corsi ed i webinar dell'utente
            getListLearningActivities: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/list-learning-activities', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutte gli utenti iscritti all'edizione del corso
            getExtraCoursesByUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-extra-courses-engagements-by-user/:fromRecord/:numRecords', {
                fromRecord: "@fromRecord",
                numRecords: "@numRecords"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Conteggia la lista dei corsi a cui è iscritto un utente
            countCourseListByUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-course-list-by-user', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            activeCoursesInPeriodByUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/active-courses-in-period-by-user', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il dettaglio del corso e dell'edizione
            getCourseAndDateDetailById: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-and-date-detail-by-id/:courseId/:courseDateId', {
                courseId: "@courseId",
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il dettaglio del corso e dell'edizione
            getCourseAndDateDetailByIdForFrontEnd: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-and-date-detail-by-id-for-frontend/:courseId/:courseDateId', {
                courseId: "@courseId",
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Cancella un file inserito dall'utente in un iscrizione ad un'edizione
            deleteCourseUserFile: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-course-user-file', {
            }, {
                    query: {
                        method: 'DELETE',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Cancella un corso extra
            deleteExtraCourse: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-extra-course/:engagementId', {
                engagementId: "@engagementId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Crea un caso di successo
            createSuccessStory: function () {
                return new FileUploader({ url: GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/create-success-story' });
            },

            // Scarica l'allegato di un caso di successo
            downloadSuccessStoryAttachmentById: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/download-success-story-attachment/:noteDetailId', {
                noteDetailId: "@noteDetailId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge un corso extra
            addExtraCourse: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-extra-course', {
            }, {
                    insert: {
                        method: 'POST',
                        isArray: false
                    }
                }),

            // Recupera la lista di progetti
            getTrainingList: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-training-list', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Conteggia il numero dei progetti
            countTrainingList: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-training-list', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera il dettaglio di un progetto
            getTrainingById: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-training-by-id/:trainingId', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Crea un progetto
            createTraining: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/create-training', {
            }, {
                    post: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                }),

            // Aggiorna un progetto
            updateTraining: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/update-training', {
            }, {
                    update: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                }),

            // Aggiunge un corso ad un progetto
            addCoursesToTraining: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-courses-to-training', {
            }, {
                    update: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                }),

            // Cancella un corso ad un progetto
            deleteCoursesFromTraining: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-courses-from-training', {
            }, {
                    update: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                }),

            // Cancella un progetto
            deleteTraining: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-training/:trainingId', {
                trainingId: "@trainingId"
            }, {
                    delete: {
                        method: 'DELETE',
                        isArray: false
                    }
                }, {
                }),

            // Invia gli inviti agli utenti inseriti in un'edizione
            sendInvitesToUserCourseDate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/send-invites-to-user-courseDate', {
                courseUserList: null
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge degli utenti ad un 'edizione tramite l'import
            addUsersFromImport: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-users-from-import', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Cancella i dati di un import da file
            deleteImportData: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-import-data', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Cancella una lista di persone creata da un import da file
            deleteList: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-list', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Aggiunge gli utenti da una lista creata da un import da file
            addUsersFromList: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/add-users-from-list', {
            }, {
                    update: {
                        method: 'POST',
                        isArray: false
                    }
                },
                {
                    cancellable: true
                }),

            // Esporta lo storico di un utente in un file excel
            exportCourseDatesHistory: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/export-courseDates-history/:fromRecord/:numRecords'
                , {
                    fromRecord: "@fromRecord",
                    numRecords: "@numRecords"
                }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Esporta in excel corsi e webinar
            exportExcelCoursesAndWebinars: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/export-excel-courses-and-webinars',
                null,
                {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera la lista degli allegati
            getAllAttachment: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-course-date-attachments-by-id/:courseId/:courseDateId', {
                courseId: "@courseId",
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),


            // Recupera il file con i dettagli delle iscrizioni di un corso
            exportCourseUsersDetails: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/export-course-users-details/:courseId/:courseDateId', {
                courseId: "@courseId",
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),


            // Recupera le domande fatte dall'utente su un corso
            getQuestionsOfUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-questions-by-referenceId/:courseDateId', {
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutte le domande di un utente
            getQuestionsByUserId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-questions-by-userId', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutte le domande di un'edizione
            getQuestionsByReferenceId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/get-questions-by-courseDateId', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Recupera tutte le domande di un'edizione
            countQuestionsByReferenceId: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-questions-by-courseDateId', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            // Invia una richiesta al docente/tutor    
            contactTeacher: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/contact-teacher', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                },
                {
                    cancellable: true
                }),

            // Elimina un'edizione
            deleteCourseDate: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/delete-courseDate/:courseId/:courseDateId', {
                courseId: "@courseId",
                courseDateId: "@courseDateId"
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),
            // Crea un engagement per il download del certificato
            createEngagementCertificationDownload: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/create-engagement-certification-download', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),
            createEngagementAttachmentDownload: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/create-engagement-material-download', {
            }, {
                    query: {
                        method: 'POST',
                        isArray: false
                    }
                }, {
                    cancellable: true
                }),

            countHistoryCreditsByUser: $resource(GlobalApplicationData.applicationContext + 'rest-api/bgacademy-mediator/count-course-list-by-user-credits', {
            }, {
                    query: {
                        method: 'GET',
                        isArray: false
                    }
                }, {
                    cancellable: true
                })
        };
        return serv;
    }]);
}